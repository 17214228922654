
import * as Components from "./Sushidon"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "sushidon"
}

