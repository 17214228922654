
import React, { Component } from 'react';
import './about.scss'

export default function () {
  console.log('our story rendering')
  return(
    <div className="about-page">
      <article style={{minHeight: '100vh'}} className="post our-story">
        <h1>Stripe Verification</h1>

        <p>
        email: Jayvee60@hotmail.com
          </p>
      </article>


    </div>
  )
}
